import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import reportWebVitals from './reportWebVitals'

/* CSS */
import './design/css/app.css'
import './design/css/box.css'
import './design/css/boxcards.css'
import './design/css/tablecards.css'
import './design/css/player.css'
import './design/css/dealer.css'
import './design/css/card.css'
import './design/css/buttons.css'
import './design/css/rates.css'
import './design/css/chips.css'
import './design/css/preloader.css'
import './design/css/result.css'
import 'react-toastify/dist/ReactToastify.css'


/* Redux */
import { Provider } from 'react-redux'
import { store } from './store'

/* Fields */
const pathname = window.location.search
const token = pathname.length > 1 ? pathname.substring(1) : ""

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(token.length > 64 ? <Provider store={store}><App /></Provider> : <div className="page-404"><span>404</span> Page not found</div>)

reportWebVitals()