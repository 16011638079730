import React, { Component } from "react"

/* Constants */
import { env, utils } from "../constants"

/* Components */
import { Picture } from "@alicorn/ep-ui"




/* Widget Preloader */
class Preloader extends Component {

    render = () => {

        const { operator } = this.props

        const logo = operator !== "makao" ? "cubago.png" : "preloader/makao.svg"

        return (
            <div className="preloader">
                <Picture className="full-image" src={`${env.endpoint}/images/bg.webp`} alt="background" />
                <div className="preloader-logo">
                    <div className="preloader-logo-text">
                        <span>ULTIMATE</span>
                        <p>TEXAS</p>
                        <span>HOLDEM</span>
                    </div>
                </div>
                <div className="preloader-indicator">
                    <Picture className="line" src={`${env.mediapoint}/images/preloader/preloader.svg`} alt="Preloader" />
                    <Picture className="star" src={`${env.mediapoint}/images/preloader/star.svg`} alt="Preloader" />
                </div>
                <p className="support">{utils.translate("Supported by")}</p>
                <Picture className="makao-logo" src={`${env.mediapoint}/images/${logo}`} alt="Preloader" />
            </div>
        )
    }

}

export default Preloader